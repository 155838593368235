import isNumber from './isNumber';
import getAssetIdFromSearch from './getAssetIdFromSearch';

export default (prevState, search) => {
  let isAssetDetailView = false;
  let assetId = null;

  // check query parameters to decide which view to display
  if (search) {
    assetId = getAssetIdFromSearch(search);
    isAssetDetailView = assetId !== null && isNumber(assetId);
  }

  if (
    prevState.isAssetDetailView !== isAssetDetailView
    && prevState.assetId !== assetId
  ) {
    return { isAssetDetailView, assetId };
  }

  return null;
};
