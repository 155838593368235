import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import history from '../../../routers/history';
import { withAssetCart } from '../../../store/AssetCartContext';
import { withCategory } from '../../../store/CategoryContext';
import AssetDetailImage from '../../molecules/AssetDetailImage/AssetDetailImage';
import AssetDetailProperties from '../../molecules/AssetDetailProperties/AssetDetailProperties';
import AssetDetailInformation from '../../molecules/AssetDetailInformation/AssetDetailInformation';
import MediathekUrlSearchParam from '../../../helpers/MediathekUrlSearchParams';
import IconButtons from '../../molecules/IconButtons/IconButtons';

const AssetDetailAside = ({ categoryContext, assetCartContext, isAssetCartPage, isCategoryPage, intl }) => {
  let context;

  if (isAssetCartPage) {
    context = assetCartContext;
  }
  if (isCategoryPage) {
    context = categoryContext;
  }


  if (assetCartContext && context.activeAsset !== undefined) {
    let cartButtonTestId = 'addAssetToAssetCartButton';
    let cartButtonText = intl.formatMessage({ id: 'asset-tile.button.add-to-asset-cart' });
    let cartButtonClassModifier = '--add';
    let cartButtonFunction = e => {
      e.preventDefault();
      assetCartContext.onAddAssetToAssetCart(context.activeAsset);
    };

    const buttonShouldDelete = assetCartContext.onHandleAssetForAssetCart(context.activeAsset.id);
    if (buttonShouldDelete) {
      cartButtonTestId = 'deleteAssetFromAssetCartButton';
      cartButtonText = intl.formatMessage({ id: 'asset-tile.button.delete-from-asset-cart' });
      cartButtonClassModifier = '--remove';
      cartButtonFunction = e => {
        e.preventDefault();
        assetCartContext.onDeleteAssetFromAssetCart(context.activeAsset.id);
      };
    }

    const downloadButtonFunction = e => {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = `${process.env.REACT_APP_DOWNLOAD_URL}/download?id=${context.activeAsset.id}`;
    };

    const buttons = [
      {
        buttonFunction: downloadButtonFunction,
        buttonClassNameIconExtension: '--download',
        buttonText: intl.formatMessage({ id: 'asset-tile.button.download' }),
        testId: 'download-test-id'
      },
      {
        buttonFunction: cartButtonFunction,
        buttonClassNameIconExtension: cartButtonClassModifier,
        buttonText: cartButtonText,
        testId: cartButtonTestId
      }
    ];


    if (Object.entries(context.activeAsset).length !== 0) {
      return (
        <div className="asset-detail-aside">

          <div className="asset-detail-aside__header-area">
            <div className="asset-detail-aside__overflow">
              <h3 className="asset-detail-aside__header">
                {context.activeAsset.name}
              </h3>
            </div>

            <Link
              className="asset-detail-aside__link"
              to={`${history.location.pathname}?${MediathekUrlSearchParam.asset}=${context.activeAsset.id}`}
              title={intl.formatMessage({ id: 'asset-detail-aside.link.to-asset-detail-view' })}
            />
          </div>
          <AssetDetailImage
            path={context.activeAsset.thumbnailTile}
            name={context.activeAsset.name}
          />

          <AssetDetailProperties
            extension={context.activeAsset.metadataAttributeList.extension}
            size={context.activeAsset.metadataAttributeList.size}
            dimension={context.activeAsset.metadataAttributeList.dimension}
            published={context.activeAsset.metadataAttributeList.published}
            colorType={context.activeAsset.metadataAttributeList.colorType}
            resolution={context.activeAsset.metadataAttributeList.resolution}
          />

          <IconButtons
            containerClassName="asset-detail-aside__buttons"
            buttons={buttons}
          />

          <AssetDetailInformation
            description={context.activeAsset.metadataFormattedText.description}
            copyright={context.activeAsset.metadataFormattedText.copyright}
            usageRights={context.activeAsset.metadataFormattedText.usageRights}
          />

        </div>
      );
    }
  }

  return (
    <div className="asset-detail-aside" />
  );
};

export default injectIntl(withCategory(withAssetCart(AssetDetailAside)));
