import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAssetCart } from '../../../store/AssetCartContext';
import IconButtons from '../IconButtons/IconButtons';

const AssetCartPageIntroduction = ({ assetCartContext, intl }) => {
  /*
  const cartButtonTestId = 'addAssetToAssetCartButton';
  const downloadAllButtonFunction = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  */

  const deleteAllFromAssetCart = event => {
    event.preventDefault();
    event.stopPropagation();
    assetCartContext.onHandleDeleteAllAssetsFromAssetCart();
  };

  const buttons = [
    /*
    {
      buttonFunction: downloadAllButtonFunction,
      buttonClassNameIconExtension: '--download-all',
      testId: cartButtonTestId,
      buttonText: intl.formatMessage({ id: 'icon-buttons.label.asset-cart-page-introduction.download-all' }),
      showbuttonlabel: true
    },
    */
    {
      buttonFunction: deleteAllFromAssetCart,
      buttonClassNameIconExtension: '--delete-all',
      buttonText: intl.formatMessage({ id: 'icon-buttons.label.asset-cart-page-introduction.delete-all' }),
      showbuttonlabel: true
    }
  ];

  return (
    <section className="asset-cart-page-introduction">

      <h1 className="asset-cart-page-introduction__headline">
        <FormattedMessage id="asset-cart-page-introduction.headline" />
        <span className="asset-cart-page-introduction__asset-count">
          (
          {`${assetCartContext.assetCart.length} `}
          <FormattedMessage id="asset-cart-page-introduction.asset-count-label" />
          )
        </span>
      </h1>

      {assetCartContext.assetCart.length >= 1
        ? (
          <Fragment>
            <IconButtons
              containerClassName="asset-cart-page-introduction__buttons"
              buttons={buttons}
            />
          </Fragment>
        )
        : ''
      }

    </section>
  );
};

export default injectIntl(withAssetCart(AssetCartPageIntroduction));
