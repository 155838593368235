import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import history from '../../../routers/history';
import PageTemplate from '../../templates/PageTemplate/PageTemplate';
import AssetDetailAside from '../../organisms/AssetDetailAside/AssetDetailAside';
import AssetDetailView from '../../organisms/AssetDetailView/AssetDetailView';
import AssetCartView from '../../organisms/AssetCartView/AssetCartView';
import { withAssetCart } from '../../../store/AssetCartContext';
import NavigationLinks from '../../../helpers/NavigationLinks';
import prepareStateValuesForAssetDetailView from '../../../helpers/functions/prepareStateValuesForAssetDetailView';

class AssetCartPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAssetDetailView: false
    };
    // this is for the case the user open the page direct per link in brouser
    this.props.assetCartContext.getAssetCart(this.props.match.params.id);
  }

  componentDidMount() {
    const active = this.props.assetCartContext.setActiveAssetInAssetCart;
    if (this.props.assetCartContext.assetCart.length >= 1) {
      active(this.props.assetCartContext.assetCart[0]);
    }
  }

  componentDidUpdate(_, prevState) {
    this.updateStateForAssetDetailView(prevState);
  }

  componentWillUnmount() {
    const reset = this.props.assetCartContext.resetActiveAsset;
    reset();
  }

  updateStateForAssetDetailView = prevState => {
    if (
      !this.props.assetCartContext.error
      && this.props.assetCartContext.assetCart.length > 0
    ) {
      const stateValues = prepareStateValuesForAssetDetailView(
        prevState,
        history.location.search
      );
      if (stateValues) {
        const { isAssetDetailView } = stateValues;
        this.setState(() => ({ isAssetDetailView }));
      }
    }
  };

  whichContextAssetAside = () => <AssetDetailAside isAssetCartPage />;

  renderView = () => (this.state.isAssetDetailView ? (
    <AssetDetailView
      assets={this.props.assetCartContext.assetCart}
      isAssetCartPage
      backToViewLink={NavigationLinks.assetCartPageLink}
      folderName="Asset Cart"
    />
  ) : (
    <AssetCartView />
  ));

  render() {
    const {
      assetCartContext: { isLoading, error }
    } = this.props;

    return (
      <PageTemplate
        assetDetailAside={
          this.state.isAssetDetailView ? null : this.whichContextAssetAside
        }
        blockElementClass={this.state.isAssetDetailView ? '' : 'page-template__main-content--S'}
      >
        {isLoading && <FormattedMessage id="loading.text" />}
        {!error ? this.renderView() : <div>{error}</div>}
      </PageTemplate>
    );
  }
}

export default withAssetCart(AssetCartPage);
