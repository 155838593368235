const getFullBreadcrumbData = activeCategory => {
  const parents = [];
  let currentObject = activeCategory;

  do {
    if (currentObject !== undefined && currentObject !== null
        && currentObject.parent !== null && currentObject.parent !== undefined) {
      parents.unshift(currentObject.parent);
      currentObject = currentObject.parent;
    }
  } while (currentObject !== null && currentObject !== undefined
  && currentObject.parent !== null && currentObject.parent !== undefined);

  return parents;
};

export default getFullBreadcrumbData;
