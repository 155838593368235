import React from 'react';
import SubCategoryLink from '../SubCategoryLink/SubCategoryLink';

const SubCategoryLinks = ({ subCategories }) => (
  <ul className="sub-category-link-list">
    {subCategories.map(subCategory => (
      <SubCategoryLink key={subCategory.id} {... subCategory} />
    ))}
  </ul>
);

export default SubCategoryLinks;
