export default {
  'de-DE': {
    'loading.text': 'Wird geladen...',
    'logo.text': 'Mediathek',
    'home-nav-link.text': 'Home',
    'search-box.placeholder': 'Suchen nach...',
    'introduction.headline': 'Willkommen in der Krombacher Mediathek',
    'introduction.text': 'In unserer Krombacher Mediathek finden Sie stets aktuelle Logos, Produktabbildungen und '
    + 'Anzeigenmotive unserer Marken und Sorten zum Download.',
    'home-page.latest': 'Neueste Einträge',
    'asset-tile.button.add-to-asset-cart': 'Zur Merkliste hinzufügen',
    'asset-tile.button.delete-from-asset-cart': 'Von der Merkliste entfernen',
    'asset-tile.button.download': 'Herunterladen',
    'asset-detail-view.link.back-to-overview-short': 'Zurück',
    'asset-detail-view.link.back-to-overview-long': 'Zurück zur Übersicht',
    'asset-detail-view.link.next': 'Vor',
    'asset-detail-view.link.previous': 'Zurück',
    'asset-detail-aside.link.to-asset-detail-view': 'Zur Detailansicht',
    'asset-detail-properties.dimension': 'Dimensionen',
    'asset-detail-properties.extension': 'Dateiformat',
    'asset-detail-properties.published': 'Freigegeben am',
    'asset-detail-properties.size': 'Größe',
    'asset-detail-properties.colorType': 'Farbtyp',
    'asset-detail-properties.resolution': 'Auflösung',
    'asset-detail-information.description': 'Beschreibung',
    'asset-detail-information.source': 'Quelle:',
    'asset-detail-information.licence': 'Beschränkung der Nutzungsrechte:',
    'asset-detail-properties.no-data': 'Keine Angabe',
    'asset-detail-properties.unknown': 'Unbekannt',
    'asset-cart-nav-link.title': 'Merkliste',
    'asset-cart-page-introduction.headline': 'Merkliste',
    'asset-cart-page-introduction.asset-count-label': 'Dateien',
    'footer.links.imprint': 'Impressum',
    'footer.links.data-protection': 'Datenschutz',
    'footer.links.terms-of-use': 'Nutzungsbedingungen',
    'footer.links.tracking': 'Google Analytics',
    'icon.home': 'Home',
    'asset-tiles-empty.asset-cart.text': 'Es befinden sich keine Elemente auf Ihrer Merkliste.',
    'asset-tiles-empty.asset-cart.description': 'Über das Hinzufügen-Icon können Sie die Merkliste füllen.',
    'asset-tiles-empty.category.text': 'Es befinden sich keine Elemente in dieser Kategorie.',
    'asset-tiles-empty.category.description': 'Wählen Sie eine andere Kategorie aus.',
    'asset-tiles-empty.latest.text': 'Es sind keine Elemente vorhanden.',
    'asset-tiles-empty.latest.description': 'Fügen Sie Elemente im Datapool hinzu.',
    'breadcrumb.home': 'Home',
    'icon-buttons.label.asset-cart-page-introduction.download-all': 'Alle downloaden',
    'icon-buttons.label.asset-cart-page-introduction.delete-all': 'Liste leeren',
    'tracking-notice.button.ignore.label': 'Ignorieren',
    'tracking-notice.button.accept.label': 'Akzeptieren',
    'tracking-notice.description.link.text': 'Datenschutzerklärung.',
    'tracking-notice.description-about.text': 'Diese Webseite verwendet Cookies um das Nutzungsverhalten zu '
        + 'analysieren und die Nutzererfahrung zu verbessern. Nähere Informationen zu der Verwendung'
        + ' von Cookies entnehmen Sie bitte unserer ',
    'tracking-notice.description-opt-out.link.text': 'Falls Sie nicht möchten, dass Cookies zum Nutzungsverhalten '
        + 'gespeichert werden, können Sie rechts mit dem Opt-Out-Button widersprechen. '
        + 'Mit dem Opt-In-Button können Sie dem wieder zustimmen.',
    'tracking-notice.button.opt-out.label': 'Opt-Out',
    'tracking-notice.button.opt-in.label': 'Opt-In',
    'tracking-notice.button.close.label': 'Schließen'
  }
};
