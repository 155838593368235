import React from 'react';
import { withApp } from '../../../store/AppContext';

const MenuButton = ({ appContext }) => {
  // BASTODO: translate text

  const valuesClose = {
    classname: 'menu-button--close',
    text: 'Close',
    appContextValue: false
  };

  const valuesOpen = {
    classname: 'menu-button--open',
    text: 'Open',
    appContextValue: true
  };

  const values = appContext.isMenuOpen ? valuesClose : valuesOpen;

  return (
    <div
      className={`menu-button ${values.classname}`}
      role="presentation"
      onClick={() => {
        appContext.setMenuState(values.appContextValue, true);
      }}
    >
      {values.text}
    </div>
  );
};

export default withApp(MenuButton);
