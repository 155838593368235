import React, { Component } from 'react';
import { getCategory } from './api';

const CategoryContext = React.createContext();
const { Provider, Consumer } = CategoryContext;

class CategoryProvider extends Component {
  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);

    this.state = {
      category: {},
      activeAsset: {},
      error: null,
      isLoading: false,
      setActiveAssetInCategory: this.setActiveAsset,
      getCategory: this.getCategory,
      resetCategory: this.resetCategory,
      resetActiveAsset: this.resetActiveAsset,
    };
  }

  resetActiveAsset = () => this.setState(() => ({ activeAsset: {} }));

  resetCategory = () => this.setState(() => ({ category: {} }));

  getCategory = async id => {
    this.setState(() => ({
      error: null,
      isLoading: true
    }));
    this.resetCategory();

    const category = await getCategory(id);
    if (!category.error) {
      if (category.payload.assets && category.payload.assets.length > 0) {
        this.setState(() => ({
          isLoading: false,
          category: category.payload,
          activeAsset: category.payload.assets[0]
        }));
      } else {
        this.setState(() => ({
          isLoading: false,
          category: category.payload,
          activeAsset: {}
        }));
      }
    } else {
      this.setState(() => ({ isLoading: false, error: category.error }));
    }
  };

  setActiveAsset = activeAsset => {
    this.setState(() => ({ activeAsset }));
  };
  /* eslint-enable react/no-unused-state */

  render() {
    return (
      <Provider
        value={this.state}
      >
        {this.props.children}
      </Provider>
    );
  }
}

function withCategory(WrappedComponent) {
  return function WithCategory(props) {
    return (
      <Consumer>
        {categoryContext => {
          if (typeof categoryContext === 'undefined') {
            throw Error('withCategory requires an CategoryProvider');
          }
          return (
            <WrappedComponent {...props} categoryContext={categoryContext} />
          );
        }}
      </Consumer>
    );
  };
}

export { CategoryProvider, CategoryContext, withCategory };
