import React from 'react';
import { Link } from 'react-router-dom';
import NavigationLinks from '../../../helpers/NavigationLinks';

const NotFoundPage = () => (
  <div className="not-found-page">
    404!
    <Link to={NavigationLinks.homePageLink}>Go to Home</Link>
  </div>
);

export default NotFoundPage;
