import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import history from '../../../routers/history';
import { withCategory } from '../../../store/CategoryContext';
import PageTemplate from '../../templates/PageTemplate/PageTemplate';
import Viewports from '../../../helpers/Viewports';
import CategoryContentView from '../../organisms/CategoryContentView/CategoryContentView';
import AssetDetailView from '../../organisms/AssetDetailView/AssetDetailView';
import AssetDetailAside from '../../organisms/AssetDetailAside/AssetDetailAside';
import NavigationLinks from '../../../helpers/NavigationLinks';
import isNumber from '../../../helpers/functions/isNumber';
import prepareStateValuesForAssetDetailView from '../../../helpers/functions/prepareStateValuesForAssetDetailView';

class CategoryPage extends PureComponent {
  constructor(props) {
    super(props);
    // TODO: thoughts for the future
    // react detect viewport size: https://www.npmjs.com/package/react-window-size
    this.state = {
      viewport: Viewports.phone,
      isAssetDetailView: false,
    };
    // this is for the case the user open the page direct per link in brouser
    this.props.categoryContext.getCategory(this.props.match.params.id);
  }

  componentDidUpdate(_, prevState) {
    // this is for all other cases, when the current category id
    // is different to category id in params

    // first check if the category was loaded with an error,
    // then try again to load category with new params
    if (
      (this.props.categoryContext.error
        && isNumber(this.props.match.params.id))
      || (!this.props.categoryContext.error
        && this.props.categoryContext.category.id
        && isNumber(this.props.match.params.id)
        && this.props.match.params.id
        !== this.props.categoryContext.category.id.toString())
    ) {
      this.props.categoryContext.getCategory(this.props.match.params.id);
    }

    this.updateStateForAssetDetailView(prevState);
  }

  componentWillUnmount() {
    const reset = this.props.categoryContext.resetActiveAsset;
    reset();
  }

  updateStateForAssetDetailView = prevState => {
    if (
      !this.props.categoryContext.error
      && this.props.categoryContext.category.id
    ) {
      const stateValues = prepareStateValuesForAssetDetailView(
        prevState,
        history.location.search
      );
      if (stateValues) {
        const { isAssetDetailView } = stateValues;
        this.setState(() => ({ isAssetDetailView }));
      }
    }
  };

  whichContextAssetAside = () => <AssetDetailAside isCategoryPage />;

  renderView = () => {
    // render the component only if we fetched the category. Otherwise we will not be able to see
    // AssetDetailView for concrete asset, if we call the assetDetail by link directly
    if (this.props.categoryContext.category.id) {
      return this.state.isAssetDetailView ? (
        <AssetDetailView
          assets={this.props.categoryContext.category.assets}
          isCategoryPage
          backToViewLink={`${NavigationLinks.categoryPageLink}/${
            this.props.categoryContext.category.id
          }`}
          folderName={`Category name: ${
            this.props.categoryContext.category.name
          }`}
        />
      ) : (
        <CategoryContentView viewport={this.state.viewport} />
      );
    }

    return null;
  };

  render() {
    const {
      categoryContext: { isLoading, error }
    } = this.props;
    return (
      <PageTemplate
        assetDetailAside={
          this.state.isAssetDetailView ? null : this.whichContextAssetAside
        }
        blockElementClass={this.state.isAssetDetailView ? '' : 'page-template__main-content--S'}
      >
        <Fragment>
          {isLoading && <FormattedMessage id="loading.text" />}
          {!error ? this.renderView() : <div>{error}</div>}
        </Fragment>
      </PageTemplate>
    );
  }
}

export default withCategory(CategoryPage);
