import React, { Component } from 'react';

const AppContext = React.createContext();
const { Provider, Consumer } = AppContext;

class AppProvider extends Component {
  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      // sometimes we do not want menu to animate (for example on selecting items in menu)
      isMenuAminated: false,
      setMenuState: this.setMenuState,
    };
  }

  setMenuState = (isMenuOpen, isMenuAminated) => {
    this.setState(() => ({ isMenuOpen, isMenuAminated }));
  };
  /* eslint-enable react/no-unused-state */

  render() {
    return (
      <Provider
        value={this.state}
      >
        {this.props.children}
      </Provider>
    );
  }
}

function withApp(WrappedComponent) {
  return function WithApp(props) {
    return (
      <Consumer>
        {appContext => {
          if (typeof appContext === 'undefined') {
            throw Error('withApp requires an AppProvider');
          }
          return <WrappedComponent {...props} appContext={appContext} />;
        }}
      </Consumer>
    );
  };
}

export { AppProvider, AppContext, withApp };
