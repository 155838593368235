import Cookies from './cookies';

const globals = {
  // if gtmConfig is defined and gtmConfig is not empty,
  gtm: {
    tag: process.env.REACT_APP_GTM_TAG,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  },
  dataPrivacyUrl: 'https://www.krombacher.de/mediathek/datenschutz'
};

class Tracking {
    static IGNORED = 'ignored';

    static ACCEPTED = 'accepted';

    static NOTDEFINED = 'notdefined';

    static eventTrackingSetupFinished = false;

    static shouldTrack() {
      return this.getStatus() === Tracking.ACCEPTED;
    }

    static getStatus() {
      if (typeof Cookies === 'undefined') {
        return Tracking.NOTDEFINED;
      }

      const fromCookie = Cookies.get('trackNotice');
      const cookieContentIsValid = fromCookie === Tracking.NOTDEFINED
            || fromCookie === Tracking.ACCEPTED
            || fromCookie === Tracking.IGNORED;

      if (cookieContentIsValid) {
        return fromCookie;
      }

      return Tracking.NOTDEFINED;
    }

    static setStatus(decision) {
      const date = new Date();
      date.setTime(date.getTime() + (60 * 60 * 24 * 31 * 1000));
      Cookies.set('trackNotice', decision, date, false);

      if (decision) {
        Tracking.setupEventTracking();
      }
    }

    static triggerEvent(trackingEvent) {
      if (!this.shouldTrack() || !window.dataLayer) {
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: trackingEvent.name,
        eventCategory: trackingEvent.category,
        eventAction: trackingEvent.action,
        eventLabel: trackingEvent.label
      });
    }

    static setupEventTracking() {
      if (this.eventTrackingSetupFinished || !this.isEnabled() || !this.shouldTrack()) {
        return;
      }

      this.injectGoogleTagManagerCode();

      this.eventTrackingSetupFinished = true;
    }

    static isEnabled() {
      return (
        globals.gtm
            && globals.gtm.tag
      );
    }

    static injectGoogleTagManagerCode() {
      if (this.eventTrackingSetupFinished || !this.isEnabled() || !this.shouldTrack()) {
        return;
      }
      let params = 'id=\'+i+dl+\'';
      if (globals.gtm.auth) {
        params = `${params}&gtm_auth=${globals.gtm.auth}`;
      }
      if (globals.gtm.preview) {
        params = `${params}&gtm_preview=${globals.gtm.preview}`;
      }

      const headScript = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?${params}';f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${globals.gtm.tag}');
                `;

      const headScriptContainer = document.createElement('script');
      headScriptContainer.type = 'text/javascript';
      headScriptContainer.innerHTML = headScript;
      document.getElementsByTagName('head')[0].appendChild(headScriptContainer);
    }
}

Tracking.setupEventTracking();

export default Tracking;
