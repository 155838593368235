import React from 'react';
import AppRouter from './routers/AppRouter';
import { AppProvider } from './store/AppContext';
import { HomeContentProvider } from './store/HomeContentContext';
import { AssetCartProvider } from './store/AssetCartContext';
import { CategoriesProvider } from './store/CategoriesContext';
import { CategoryProvider } from './store/CategoryContext';
import './sass/main.scss';
import './fonts/krombacher/serif/Krombacher-SerifRegular.otf';
import './fonts/krombacher/serif/Krombacher-SerifRegular.woff';
import './fonts/krombacher/serif/Krombacher-SerifRegular.woff2';
import './fonts/krombacher/serif/Krombacher-SerifRegularItalic.otf';
import './fonts/krombacher/serif/Krombacher-SerifRegularItalic.woff';
import './fonts/krombacher/serif/Krombacher-SerifRegularItalic.woff2';
import './fonts/krombacher/serif/Krombacher-SerifDemi.otf';
import './fonts/krombacher/serif/Krombacher-SerifDemi.woff';
import './fonts/krombacher/serif/Krombacher-SerifDemi.woff2';
import './fonts/krombacher/serif/Krombacher-SerifDemiItalic.otf';
import './fonts/krombacher/serif/Krombacher-SerifDemiItalic.woff';
import './fonts/krombacher/serif/Krombacher-SerifDemiItalic.woff2';
import './fonts/krombacher/sans/Krombacher-SansRegular.otf';
import './fonts/krombacher/sans/Krombacher-SansRegular.woff';
import './fonts/krombacher/sans/Krombacher-SansRegular.woff2';
import './fonts/krombacher/sans/Krombacher-SansRegularItalic.otf';
import './fonts/krombacher/sans/Krombacher-SansRegularItalic.woff';
import './fonts/krombacher/sans/Krombacher-SansRegularItalic.woff2';
import './fonts/krombacher/sans/Krombacher-SansDemi.otf';
import './fonts/krombacher/sans/Krombacher-SansDemi.woff';
import './fonts/krombacher/sans/Krombacher-SansDemi.woff2';
import './fonts/krombacher/sans/Krombacher-SansDemiItalic.otf';
import './fonts/krombacher/sans/Krombacher-SansDemiItalic.woff';
import './fonts/krombacher/sans/Krombacher-SansDemiItalic.woff2';

const MediathekApp = () => (
  <AppProvider>
    <HomeContentProvider>
      <CategoriesProvider>
        <CategoryProvider>
          <AssetCartProvider>
            <AppRouter />
          </AssetCartProvider>
        </CategoryProvider>
      </CategoriesProvider>
    </HomeContentProvider>
  </AppProvider>
);

export default MediathekApp;
