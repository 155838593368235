import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withCategory } from '../../../store/CategoryContext';
import AssetTiles from '../../molecules/AssetTiles/AssetTiles';
import SubCategoryLinks from '../../molecules/SubCategoryLinks/SubCategoryLinks';
import Breadcrumb from '../../molecules/Breadcrumb/Breadcrumb';
import PageViewTitle from '../../molecules/PageViewTitle/PageViewTitle';

const CategoryContentView = ({
  categoryContext: { category, error, isLoading, setActiveAssetInCategory }
}) => (
  <div className="category-content-view">
    {isLoading && <FormattedMessage id="loading.text" />}
    {!error ? (
      <Fragment>
        <Breadcrumb activeCategoryId={category.id} />

        <PageViewTitle title={category.name} />

        {category.children && (
          <SubCategoryLinks subCategories={category.children} />
        )}
        {category.assets && (
          <AssetTiles
            assets={category.assets}
            setActiveAsset={setActiveAssetInCategory}
            isAdd
            emptyTextCase="category"
          />
        )}
      </Fragment>
    ) : (
      <div>{error}</div>
    )}
  </div>
);

export default withCategory(CategoryContentView);
