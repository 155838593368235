import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import HomePage from '../components/pages/HomePage/HomePage';
import CategoryPage from '../components/pages/CategoryPage/CategoryPage';
import AssetCartPage from '../components/pages/AssetCartPage/AssetCartPage';
import NotFoundPage from '../components/pages/NotFoundPage/NotFoundPage';
import NavigationLinks from '../helpers/NavigationLinks';

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path={NavigationLinks.homePageLink} component={HomePage} exact />
      <Route path={NavigationLinks.latestPageLink} component={HomePage} />
      <Route path={`${NavigationLinks.categoryPageLink}/:id`} component={CategoryPage} />
      <Route path={NavigationLinks.assetCartPageLink} component={AssetCartPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default AppRouter;
