import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class AssetTilesEmpty extends Component {
  getIcon() {
    if (this.props.emptyTextCase === 'asset-cart') {
      return <span className="asset-tiles-empty__icon" />;
    }
    return '';
  }

  render() {
    if (!this.props.emptyTextCase) {
      return '';
    }
    return (
      <div className="asset-tiles-empty">
        <p className="asset-tiles-empty__text">
          <FormattedMessage id={`asset-tiles-empty.${this.props.emptyTextCase}.text`} />
        </p>
        <p className="asset-tiles-empty__description">
          {this.getIcon()}
          <FormattedMessage id={`asset-tiles-empty.${this.props.emptyTextCase}.description`} />
        </p>
      </div>
    );
  }
}

export default AssetTilesEmpty;
