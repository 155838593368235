import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import de from 'react-intl/locale-data/de';
import * as serviceWorker from './serviceWorker';
import MediathekApp from './MediathekApp';
import messages from './i18n/messages';

addLocaleData(de);

ReactDOM.render(
  <IntlProvider locale="de-DE" messages={messages['de-DE']}>
    <MediathekApp />
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
