import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

function showOptOutBanner(event) {
  event.preventDefault();
  const optOutBanner = document.getElementById('js-tracking-notice');
  optOutBanner.style.display = 'block';
}


function getYear() {
  const year = new Date().getFullYear();
  return ` ${year} Krombacher`;
}

const Footer = () => (
  <footer className="footer">
    <div className="footer__links">
      <a
        className="footer__link"
        href="https://www.krombacher.de/mediathek/impressum"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedHTMLMessage id="footer.links.imprint" />
      </a>
      {' | '}
      <a
        className="footer__link"
        href="https://www.krombacher.de/mediathek/datenschutz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedHTMLMessage id="footer.links.data-protection" />
      </a>
      {' | '}
      <a
        className="footer__link"
        href="https://www.krombacher.de/mediathek/nutzungsbedingungen"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedHTMLMessage id="footer.links.terms-of-use" />
      </a>
      {' | '}
      <a
        className="footer__link"
        href="/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={showOptOutBanner}
      >
        <FormattedHTMLMessage id="footer.links.tracking" />
      </a>
    </div>


    <div className="footer__copyright">
      &copy;
      {getYear()}
    </div>
  </footer>
);

export default Footer;
