import React from 'react';
import { Link } from 'react-router-dom';
import { withCategories } from '../../../store/CategoriesContext';

import NavigationLinks from '../../../helpers/NavigationLinks';

const SubCategoryLink = ({ name, id, categoriesContext }) => (
  <li className="sub-category-list-element">
    <Link
      className="sub-category-link"
      to={`${NavigationLinks.categoryPageLink}/${id}`}
      onClick={() => {
        categoriesContext.updateCategoriesVisibility(id);
      }}
    >
      <span className="sub-category-link-text">{name}</span>
    </Link>
  </li>
);

export default withCategories(SubCategoryLink);
