import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import NavigationLinks from '../../../helpers/NavigationLinks';
import logo from './logo.svg';

const Logo = ({ intl }) => (
  <Link to={NavigationLinks.homePageLink} className="logo" title={intl.formatMessage({ id: 'logo.text' })}>
    <img className="logo__image" src={logo} alt={intl.formatMessage({ id: 'logo.text' })} />
    <span className="logo__text"><FormattedMessage id="logo.text" /></span>
  </Link>
);

export default injectIntl(Logo);
