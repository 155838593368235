import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import history from '../../../routers/history';
import NavigationLinks from '../../../helpers/NavigationLinks';
import { withCategories } from '../../../store/CategoriesContext';
import { withCategory } from '../../../store/CategoryContext';
import { withApp } from '../../../store/AppContext';
import HomeNavLink from '../../atoms/HomeNavLink/HomeNavLink';
import MenuNavLink from '../../atoms/MenuNavLink/MenuNavLink';

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategoryId: null,
      firstInActiveTreeId: []
    };
  }

  componentDidMount() {
    const pageTemplateMain = document.getElementById('pageTemplateMain');
    if (pageTemplateMain) {
      pageTemplateMain.addEventListener('click', this.closeSidebarMenu);
    }

    // reload sidebar menu, at the fist time when the category is defined in category context
    if (this.props.categoryContext.category.id) {
      this.triggerSidebarMenuReload();
    }
  }

  shouldComponentUpdate() {
    // re-render sidebar menu when:
    return (
      history.location.pathname.includes(NavigationLinks.categoryPageLink)
      || history.location.pathname === NavigationLinks.homePageLink
      || this.props.categoryContext.category.id !== this.state.activeCategoryId
    );
  }

  componentDidUpdate(_, prevState) {
    // reload sidebar menu, if the user goes to the link direktly
    if (
      this.props.categoryContext.category.id
      && this.props.categoriesContext.categoriesMap
      && prevState.activeCategoryId !== this.props.categoryContext.category.id
    ) {
      this.triggerSidebarMenuReload();
    }
  }

  componentWillUnmount() {
    const pageTemplateMain = document.getElementById('pageTemplateMain');
    if (pageTemplateMain) {
      pageTemplateMain.removeEventListener('click', this.closeSidebarMenu);
    }
  }

  closeSidebarMenu = e => {
    if (this.props.appContext.isMenuOpen) {
      this.props.appContext.setMenuState(false, true);
    }
  };

  triggerSidebarMenuReload = () => {
    this.setState(() => ({
      activeCategoryId: this.props.categoryContext.category.id,
      firstInActiveTreeId: this.collectFirstInActiveTree()
    }));
    this.props.categoriesContext.updateCategoriesVisibility(this.props.categoryContext.category.id);
  };

  collectFirstInActiveTree = () => {
    let activeCategory = null;
    const parentTreeFromActive = [];

    if (this.props.categoriesContext.categoriesMap) {
      activeCategory = this.props.categoriesContext.categoriesMap.get(this.props.categoryContext.category.id);
      if (activeCategory) {
        parentTreeFromActive.push(activeCategory.id);
        while (activeCategory.parent) {
          activeCategory = this.props.categoriesContext.categoriesMap.get(activeCategory.parent.id);
          parentTreeFromActive.push(activeCategory.id);
        }
      }
    }
    return parentTreeFromActive;
  };

  renderNavLinksCssClasses = item => {
    const cssClasses = [];
    cssClasses.push('sidebar-menu__list-item');
    cssClasses.push(item.isVisible ? '' : 'sidebar-menu__list-item--hide');
    cssClasses.push(this.state.firstInActiveTreeId.includes(item.id) ? 'sidebar-menu__list-item--in-active-tree' : '');
    cssClasses.push(!item.children ? 'sidebar-menu__list-item--has-no-children' : '');
    return cssClasses;
  };

  generateSidebar = (menuItemsAtOneLevel, isFirstLevelList) => {
    const multiline = (
      <ul className="sidebar-menu__list">
        {menuItemsAtOneLevel.map(item => (
          <li data-testid={item.id} key={item.id} className={this.renderNavLinksCssClasses(item).join(' ')}>
            <MenuNavLink
              isFirstLevel={isFirstLevelList}
              to={`${NavigationLinks.categoryPageLink}/${item.id}`}
              onClick={() => {
                this.props.categoriesContext.updateCategoriesVisibility(item.id);
                // prevent animation on selecting element in menu
                this.props.appContext.setMenuState(true, false);
              }}
            >
              {item.name}
            </MenuNavLink>
            {item.children && item.children.length > 0 ? this.generateSidebar(item.children, false) : null}
          </li>
        ))}
      </ul>
    );

    return multiline;
  };

  render() {
    let menu = <div>{this.props.categoriesContext.error}</div>;
    if (this.props.categoriesContext.categories.length !== 0) {
      menu = this.generateSidebar(this.props.categoriesContext.categories, true);
    }

    return (
      <div className="sidebar-menu">
        <HomeNavLink
          to={NavigationLinks.homePageLink}
          onClick={() => {
            this.props.categoriesContext.updateCategoriesVisibility();
            // prevent animation on selecting element in menu
            this.props.appContext.setMenuState(true, false);
            // reset active category
            this.props.categoryContext.resetCategory();
          }}
        />
        {this.props.categoriesContext.isLoading && <FormattedMessage id="loading.text" />}
        {menu}
      </div>
    );
  }
}

export default withApp(withCategory(withCategories(SidebarMenu)));
