import React, { memo } from 'react';
import MenuButton from '../../atoms/MenuButton/MenuButton';
import AssetCartNavLink from '../../molecules/AssetCartNavLink/AssetCartNavLink';
import Logo from '../../molecules/Logo/Logo';

const Header = () => (
  <header className="header">
    <MenuButton />
    <Logo />
    <AssetCartNavLink />
  </header>
);

export default memo(Header);
