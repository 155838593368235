const Cookies = {
  get(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }

    return null;
  },

  set(name, value, expiryDate, withDomain) {
    let domain = '';
    if (withDomain) {
      const url = window.location.href;
      const urlArray = url.split('/');
      const domainNamePort = urlArray[2];
      const domainNamePortArray = domainNamePort.split(':');
      const subdomainName = domainNamePortArray[0];
      if (subdomainName !== 'localhost') {
        const subdomainNameArray = subdomainName.split('.');
        const domainName = `${subdomainNameArray[subdomainNameArray.length - 2]}
            .${subdomainNameArray[subdomainNameArray.length - 1]}`;
        domain = `domain=.${domainName};`;
      }
    }

    const content = `${name}=${value};`;
    const expires = `expires=${expiryDate.toGMTString()};`;
    const path = 'path=/';

    document.cookie = content + expires + domain + path;
  }
};

export default Cookies;
