import React, { Fragment } from 'react';
import Button from '../../atoms/Button/Button';

const IconButtons = props => (
  <div className={`icon-buttons ${props.containerClassName ? props.containerClassName : ''}`}>
    {props.buttons.map(button => {
      let buttonLabel = '';

      if (button.showbuttonlabel !== undefined) {
        buttonLabel = (
          <span className="icon-buttons__button-label">
            <span>{button.buttonText}</span>
          </span>
        );
      }
      return (
        <Fragment
          key={button.testId + props.containerClassName}
        >
          <Button
            className={`icon-buttons__button icon-buttons__button${button.buttonClassNameIconExtension}`}
            data-testid={button.testId}
            onClick={button.buttonFunction}
            title={button.buttonText}
            key={button.testId}
          />
          {buttonLabel}
        </Fragment>
      );
    })}
  </div>
);

export default IconButtons;
