import React, { Component } from 'react';
import { getAssetCart } from './api';

const AssetCartContext = React.createContext();
const { Provider, Consumer } = AssetCartContext;

class AssetCartProvider extends Component {
  localStorageAssetCartIds = 'assetCartIds';

  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);

    this.state = {
      assetCart: [],
      activeAsset: {},
      error: null,
      isLoading: false,
      onAddAssetToAssetCart: this.handleAddAssetToAssetCart,
      onDeleteAssetFromAssetCart: this.handleDeleteAssetFromAssetCart,
      setActiveAssetInAssetCart: this.setActiveAsset,
      getAssetCart: this.getAssetCart,
      onHandleAssetForAssetCart: this.isAssetInAssetCart,
      onHandleDeleteAllAssetsFromAssetCart: this.handleDeleteAllAssetsFromAssetCart,
      resetActiveAsset: this.resetActiveAsset,
    };
  }

  componentDidMount() {
    this.getAssetCart();
  }

  componentDidUpdate(_, prevState) {
    // update localStorage if there were any changes on assetCart
    if (prevState.assetCart.length !== this.state.assetCart.length) {
      const json = JSON.stringify(this.state.assetCart.map(asset => asset.id));
      localStorage.setItem(this.localStorageAssetCartIds, json);
    }
  }

  getAssetCart = async () => {
    // load from storage if exist
    try {
      const assetCartIds = JSON.parse(
        localStorage.getItem(this.localStorageAssetCartIds)
      );
      if (assetCartIds && assetCartIds.length > 0) {
        this.setState(() => ({ error: null, isLoading: true }));

        const assetCart = await getAssetCart(assetCartIds);
        if (assetCart && !assetCart.error) {
          const payloadFiltered = assetCart.payload.filter(
            asset => asset != null
          );
          this.setState(() => ({
            isLoading: false,
            assetCart: payloadFiltered,
          }));
          const newJson = JSON.stringify(payloadFiltered.map(asset => asset.id));
          localStorage.setItem(this.localStorageAssetCartIds, newJson);
        } else {
          this.setState(() => ({ isLoading: false, error: assetCart.error }));
        }
      }
    } catch (e) {
      this.setState(() => ({
        isLoading: false,
        error:
          'Error: it was not possible to parse category ids from locale storage'
      }));
    }
    return false;
  };

  resetActiveAsset = () => this.setState(() => ({ activeAsset: {} }));

  setActiveAsset = activeAsset => {
    this.setState(() => ({ activeAsset }));
  };
  /* eslint-enable react/no-unused-state */

  isAssetInAssetCart = id => this.state.assetCart.filter(object => object.id === id).length > 0 ;

  handleDeleteAssetFromAssetCart = id => {
    this.setState(prevState => ({
      assetCart: prevState.assetCart.filter(asset => asset.id !== id)
    }));
    if (this.state.activeAsset.id === id) {
      this.resetActiveAsset();
    }
  };

  handleAddAssetToAssetCart = asset => {
    if (
      !this.state.assetCart.find(assetInState => assetInState.id === asset.id)
    ) {
      this.setState(prevState => ({
        assetCart: [...prevState.assetCart, asset]
      }));
    }
  };

  handleDeleteAllAssetsFromAssetCart = () => {
    this.setState(() => ({
      assetCart: []
    }));
    this.resetActiveAsset();
  };


  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withAssetCart(WrappedComponent) {
  return function WithAssetCart(props) {
    return (
      <Consumer>
        {assetCartContext => {
          if (typeof assetCartContext === 'undefined') {
            throw Error('withAssetCart requires an AssetCartProviders');
          }
          return (
            <WrappedComponent {...props} assetCartContext={assetCartContext} />
          );
        }}
      </Consumer>
    );
  };
}

export { AssetCartProvider, AssetCartContext, withAssetCart };
