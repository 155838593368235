import React from 'react';
import { FormattedMessage } from 'react-intl';

const Introduction = () => (
  <section className="introduction">
    <h1 className="introduction__headline"><FormattedMessage id="introduction.headline" /></h1>
    <p className="introduction__text"><FormattedMessage id="introduction.text" /></p>
  </section>
);

export default Introduction;
