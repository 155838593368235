import React from 'react';

const AssetDetailImage = props => (
  <div className="asset-detail__image">
    <img
      src={props.path}
      alt={props.name}
    />
  </div>
);

export default AssetDetailImage;
