import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import convertToDateObject from '../../../helpers/functions/convertToDateObject';
import formatBytes from '../../../helpers/functions/formatBytes';

const AssetDetailProperties = props => {
  const sizeFormat = props.size ? formatBytes(props.size) : (
    <FormattedMessage
      id="asset-detail-properties.unknown"
    />
  );
  let sizeText;
  let sizeValue;
  if (sizeFormat) {
    sizeText = (
      <div className="asset-detail-properties__column-name">
        <FormattedMessage
          id="asset-detail-properties.size"
        />
      </div>
    );
    sizeValue = (
      <div className="asset-detail-properties__column-value">
        { sizeFormat }
      </div>
    );
  }

  const labelsAndValues = {};
  const categories = Object.keys(props);

  categories.forEach(propCategory => {
    if (propCategory !== 'size' && propCategory !== 'published') {
      labelsAndValues[propCategory] = {
        label: (
          <div className="asset-detail-properties__column-name">
            <FormattedMessage
              id={`asset-detail-properties.${propCategory}`}
            />
          </div>
        ),
        value: (
          <div className={`asset-detail-properties__column-value asset-detail-properties__${propCategory}`}>
            {props[propCategory]}
          </div>
        ),
        show: !!props[propCategory]
      };
    }
  });

  return (
    <div className="asset-detail-properties">
      <div className="asset-detail-properties__row">
        {labelsAndValues.extension && labelsAndValues.extension.show ? labelsAndValues.extension.label : ''}
        {labelsAndValues.extension && labelsAndValues.extension.show ? labelsAndValues.extension.value : ''}
      </div>
      <div className="asset-detail-properties__row">
        {sizeText}
        {sizeValue}
      </div>
      <div className="asset-detail-properties__row">
        {labelsAndValues.dimension && labelsAndValues.dimension.show ? labelsAndValues.dimension.label : ''}
        {labelsAndValues.dimension && labelsAndValues.dimension.show ? labelsAndValues.dimension.value : ''}
      </div>
      <div className="asset-detail-properties__row">
        <div className="asset-detail-properties__column-name">
          <FormattedMessage
            id="asset-detail-properties.published"
          />
        </div>
        <div className="asset-detail-properties__column-value">
          {props.published ? (
            <FormattedDate
              value={convertToDateObject(props.published)}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
          ) : <FormattedMessage id="asset-detail-properties.unknown" />}
        </div>
      </div>
      <div className="asset-detail-properties__row">
        {labelsAndValues.colorType && labelsAndValues.colorType.show ? labelsAndValues.colorType.label : ''}
        {labelsAndValues.colorType && labelsAndValues.colorType.show ? labelsAndValues.colorType.value : ''}
      </div>
      <div className="asset-detail-properties__row">
        {labelsAndValues.resolution && labelsAndValues.resolution.show ? labelsAndValues.resolution.label : ''}
        {labelsAndValues.resolution && labelsAndValues.resolution.show ? labelsAndValues.resolution.value : ''}
      </div>
    </div>
  );
};

export default AssetDetailProperties;
