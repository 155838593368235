import React from 'react';

const Button = props => (
  <button
    className={`button ${props.newClassName ? props.newClassName : ''}`}
    type="submit"
    {...props}
  >
    {props.children}
  </button>
);

export default Button;
