import React, { Component } from 'react';
import { getLatestAssets } from './api';

const HomeContentContext = React.createContext();
const { Provider, Consumer } = HomeContentContext;

class HomeContentProvider extends Component {
  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);

    this.state = {
      latestAssets: [],
      error: null,
      isLoading: false,
      getHomeContent: this.getHomeContent,
    };
  }

  getHomeContent = async () => {
    this.setState(() => ({ error: null, isLoading: true }));

    const [latestAssets] = await Promise.all([getLatestAssets()]);
    if (!latestAssets.error) {
      this.setState(() => ({
        isLoading: false,
        latestAssets: latestAssets.payload
      }));
    } else {
      this.setState(() => ({ isLoading: false, error: latestAssets.error }));
    }
  };
  /* eslint-enable react/no-unused-state */

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withHomeContent(WrapperComponent) {
  return function WithHomeContent(props) {
    return (
      <Consumer>
        {homeContentContext => {
          if (typeof homeContentContext === 'undefined') {
            throw Error('withHomeContent requires an HomeContentProvider');
          }
          return (
            <WrapperComponent
              {...props}
              homeContentContext={homeContentContext}
            />
          );
        }}
      </Consumer>
    );
  };
}

export { HomeContentProvider, HomeContentContext, withHomeContent };
