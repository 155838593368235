import React from 'react';
import { withAssetCart } from '../../../store/AssetCartContext';
import AssetTiles from '../../molecules/AssetTiles/AssetTiles';
import NavigationLinks from '../../../helpers/NavigationLinks';
import AssetCartPageIntroduction from '../../molecules/AssetCartPageIntroduction/AssetCartPageIntroduction';


const AssetCartView = ({ assetCartContext }) => (
  <div className="asset-cart-view">

    <AssetCartPageIntroduction />

    <AssetTiles
      assets={assetCartContext.assetCart}
      setActiveAsset={assetCartContext.setActiveAssetInAssetCart}
      urlContext={NavigationLinks.assetCartPageLink}
      isDelete
      emptyTextCase="asset-cart"
    />

  </div>
);

export default withAssetCart(AssetCartView);
