import React from 'react';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import NavigationLinks from '../../../helpers/NavigationLinks';
import history from '../../../routers/history';
import { withAssetCart } from '../../../store/AssetCartContext';
import { withCategories } from '../../../store/CategoriesContext';
import { withApp } from '../../../store/AppContext';

const AssetCartNavLink = ({ categoriesContext, appContext, assetCartContext, intl }) => {
  const className = 'asset-cart-nav-link';

  const title = intl.formatMessage({ id: 'asset-cart-nav-link.title' });

  const onClickFunction = () => {
    categoriesContext.updateCategoriesVisibility();
    const isAssetCartSelected = history.location.pathname.includes(
      NavigationLinks.assetCartPageLink
    );
    // animate menu on
    // case1: user navigates by URL to assetcart with opend menu and click on assetcart item
    // case2: user navigates in sidebarmenu and clicks on assetcart item
    appContext.setMenuState(
      false,
      (appContext.isMenuOpen && isAssetCartSelected)
      || (appContext.isMenuOpen && !isAssetCartSelected)
    );
  };

  return (
    <NavLink
      to={NavigationLinks.assetCartPageLink}
      onClick={onClickFunction}
      activeClassName={`${className}--active`}
      className={className}
      title={title}
    >
      <span className={`${className}__title`}>
        {title}
      </span>
      <span className={`${className}__number-container`}>
        <span className={`${className}__number`}>{assetCartContext.assetCart.length}</span>
      </span>
    </NavLink>
  );
};

export default injectIntl(withAssetCart(withApp(withCategories(AssetCartNavLink))));
