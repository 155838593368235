import React from 'react';
import { injectIntl } from 'react-intl';
import { withAssetCart } from '../../../store/AssetCartContext';
import { withCategory } from '../../../store/CategoryContext';
import formatBytes from '../../../helpers/functions/formatBytes';
import IconButtons from '../IconButtons/IconButtons';


const AssetTile = ({ asset, assetCartContext, categoryContext, intl }) => {
  let context = null;
  let nameOfClass = 'asset-tile';

  if (categoryContext) {
    context = categoryContext;
    if (context.activeAsset) {
      if (context.activeAsset.id === asset.id) {
        nameOfClass = 'asset-tile asset-tile--active';
      }
    }
  }

  if (assetCartContext) {
    context = assetCartContext;
    if (context.activeAsset) {
      if (context.activeAsset.id === asset.id) {
        nameOfClass = 'asset-tile asset-tile--active';
      }
    }
  }

  let bytes = '';
  const byteValue = formatBytes(asset.metadataAttributeList.size);
  if (byteValue) {
    bytes = (
      <span className="asset-tile__properties asset-tile__properties-size">
        {' '}
(
        { byteValue }
)
      </span>
    );
  }


  let cartButtonClassModifier = '--add';
  let cartButtonText = intl.formatMessage({ id: 'asset-tile.button.add-to-asset-cart' });
  let cartButtonTestId = 'addAssetToAssetCartButton';
  let cartButtonFunction = e => {
    e.preventDefault();
    e.stopPropagation();
    assetCartContext.onAddAssetToAssetCart(asset);
  };

  const buttonShouldDelete = assetCartContext.onHandleAssetForAssetCart(asset.id);
  if (buttonShouldDelete) {
    cartButtonTestId = 'deleteAssetFromAssetCartButton';
    cartButtonClassModifier = '--remove';
    cartButtonText = intl.formatMessage({ id: 'asset-tile.button.delete-from-asset-cart' });
    cartButtonFunction = e => {
      e.preventDefault();
      e.stopPropagation();
      assetCartContext.onDeleteAssetFromAssetCart(asset.id);
    };
  }

  const downloadButtonFunction = e => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${process.env.REACT_APP_DOWNLOAD_URL}/download?id=${asset.id}`;
  };

  const buttons = [
    {
      buttonFunction: downloadButtonFunction,
      buttonClassNameIconExtension: '--download',
      buttonText: intl.formatMessage({ id: 'asset-tile.button.download' }),
      testId: 'download-test-id'
    },
    {
      buttonFunction: cartButtonFunction,
      buttonClassNameIconExtension: cartButtonClassModifier,
      buttonText: cartButtonText,
      testId: cartButtonTestId
    },
  ];

  return (
    <div className={nameOfClass}>
      <section className="asset-tile__upper-section">
        <img
          className="asset-tile__image"
          src={asset.thumbnailTile}
          alt={asset.name}
        />

      </section>
      <section className="asset-tile__lower-section">
        <p className="asset-tile__name">{asset.name}</p>
        <p className="asset-tile__properties">
          <span className="asset-tile__properties asset-tile__properties-extension">
            { asset.metadataAttributeList.extension }
          </span>
          {bytes}
          <span className="asset-tile__properties asset-tile__properties-dimension">
            { asset.metadataAttributeList.dimension }
          </span>
        </p>

        <IconButtons
          containerClassName="asset-tile__buttons"
          buttons={buttons}
        />

      </section>

    </div>
  );
};

export default injectIntl(withCategory(withAssetCart(AssetTile)));
