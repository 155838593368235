import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import NavigationLinks from '../../../helpers/NavigationLinks';
import { withCategories } from '../../../store/CategoriesContext';
import { withCategory } from '../../../store/CategoryContext';
import { withApp } from '../../../store/AppContext';
import getFullBreadcrumbData from '../../../helpers/functions/getFullBreadcrumbData';

const Breadcrumb = ({
  activeCategoryId,
  categoriesContext: { categoriesMap, updateCategoriesVisibility },
  appContext: { setMenuState },
  categoryContext: { resetCategory },
}) => {
  let activeCategory = null;
  let parents = [];
  if (categoriesMap) {
    activeCategory = categoriesMap.get(activeCategoryId);
    if (getFullBreadcrumbData(activeCategory).length > 0) {
      parents = getFullBreadcrumbData(activeCategory).map(parent => (
        <Link
          className="breadcrumb__link"
          key={parent.id}
          to={`${NavigationLinks.categoryPageLink}/${parent.id}`}
          onClick={() => {
            updateCategoriesVisibility(parent.id);
          }}
        >
          {parent.name}
        </Link>
      ));
    }
  }

  return (
    <div className="breadcrumb">
      <Link
        className="breadcrumb__link breadcrumb__link--home"
        to={NavigationLinks.homePageLink}
        onClick={() => {
          updateCategoriesVisibility();
          // prevent animation on selecting element in menu
          setMenuState(false, false);
          // reset active category
          resetCategory();
        }}
      >
        <FormattedMessage id="breadcrumb.home" />
      </Link>
      {activeCategory
        && (activeCategory.parent ? parents : '')}
    </div>
  );
};

export default withCategory(withApp(withCategories(Breadcrumb)));
