import React, { Fragment } from 'react';
import { withHomeContent } from '../../../store/HomeContentContext';
import Introduction from '../../molecules/Introduction/Introduction';
import LatestAssets from '../../molecules/LatestAssets/LatestAssets';

const HomeContentView = props => (
  <div>
    <Fragment>
      <Introduction />
      <LatestAssets />
    </Fragment>
  </div>
);

export default withHomeContent(HomeContentView);
