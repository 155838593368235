import React from 'react';
import { withApp } from '../../../store/AppContext';
import Header from '../../organisms/Header/Header';
import SidebarMenu from '../../molecules/SidebarMenu/SidebarMenu';
import Footer from '../../organisms/Footer/Footer';
import Breakpoints from '../../../helpers/Breakpoints';
import TrackingNotice from '../../molecules/TrackingNotice/TrackingNotice';

const PageTemplate = ({
  children,
  assetDetailAside: AssetDetailAside,
  appContext, blockElementClass
}) => {
  const renderWrappersCssClasses = () => {
    // prevent animation of sidbar menu on desktop
    const animated = appContext.isMenuAminated && window.innerWidth <= Breakpoints.breakpointL ? '-animated' : '';
    const showHideClass = appContext.isMenuOpen
      ? 'page-template__wrapper--show-menu'
      : 'page-template__wrapper--hide-menu';
    return ['page-template__wrapper', `${showHideClass}${animated}`];
  };
  return (
    <div className="page-template">
      <div className="page-template__header">
        <Header />
      </div>
      <div className={renderWrappersCssClasses().join(' ')}>
        <div className="page-template__sidebar-menu">
          <SidebarMenu />
        </div>
        <div id="pageTemplateMain" className="page-template__main">
          <div
            className={`page-template__main-content ${
              appContext.isMenuOpen
                ? 'page-template__main-content--show-menu'
                : ''
            } ${blockElementClass || ''}`}
          >
            <div className="page-template__organism-container">
              {children}
            </div>
            <div className="page-template__footer">
              <Footer />
            </div>
          </div>
        </div>

        {AssetDetailAside && (
          <div className="page-template__asset-detail-aside">
            <AssetDetailAside />
          </div>
        )}
      </div>
      <TrackingNotice />
    </div>
  );
};

export default withApp(PageTemplate);
