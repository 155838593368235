import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuNavLink = props => (


  <NavLink
    className="menu-nav-link"
    to={props.to}
    onClick={props.onClick}
  >
    <div className="menu-nav-link__text">{props.children}</div>
  </NavLink>
);

export default MenuNavLink;
