import React from 'react';
import AssetTile from '../AssetTile/AssetTile';
import AssetTilesEmpty from '../AssetTilesEmpty/AssetTilesEmpty';
import history from '../../../routers/history';
import MediathekUrlSearchParam from '../../../helpers/MediathekUrlSearchParams';
import Breakpoints from '../../../helpers/Breakpoints';

const AssetTiles = ({ assets, urlContext, setActiveAsset, isAdd = false, isDelete = false, emptyTextCase,
  isOnHomepage = false }) => (
    <div className="asset-tiles">
      {/* safari bug fix. ul does not grow when li elements are added. */}
      {assets.length === 0
        ? (
          <AssetTilesEmpty
            emptyTextCase={emptyTextCase}
          />
        ) : (
          <ul className="asset-tiles__list">
            {assets.map(asset => (
              <li
                className="asset-tiles__list-element"
                key={asset.id}
                role="presentation"
                onClick={() => {
                  setActiveAsset(asset);
                  if (window.innerWidth <= Breakpoints.breakpointL || isOnHomepage) {
                    history.push(`${urlContext}?${MediathekUrlSearchParam.asset}=${asset.id}`);
                  }
                }}
              >
                <AssetTile
                  asset={asset}
                  isAdd={isAdd}
                  isDelete={isDelete}
                />
              </li>
            ))}
          </ul>
        )
    }
    </div>
);

export default AssetTiles;
