import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withHomeContent } from '../../../store/HomeContentContext';
import AssetTiles from '../AssetTiles/AssetTiles';
import NavigationLinks from '../../../helpers/NavigationLinks';

const HomePageAssetTiles = props => {
  if (props.homeContentContext.latestAssets.length > 0) {
    return (
      <div className="latest-assets">
        <h3 className="latest-assets__headline">
          <FormattedMessage id="home-page.latest" />
        </h3>
        <AssetTiles
          assets={props.homeContentContext.latestAssets}
          setActiveAsset={() => {}}
          urlContext={NavigationLinks.latestPageLink}
          isAdd
          emptyTextCase="latest"
          isOnHomepage
        />
      </div>
    );
  }
  return '';
};

export default withHomeContent(HomePageAssetTiles);
