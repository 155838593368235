import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import history from '../../../routers/history';
import { withAssetCart } from '../../../store/AssetCartContext';
import Button from '../../atoms/Button/Button';
import MediathekUrlSearchParams from '../../../helpers/MediathekUrlSearchParams';
import getAssetIdFromSearch from '../../../helpers/functions/getAssetIdFromSearch';
import { withCategory } from '../../../store/CategoryContext';
import AssetDetailProperties from '../../molecules/AssetDetailProperties/AssetDetailProperties';
import AssetDetailInformation from '../../molecules/AssetDetailInformation/AssetDetailInformation';
import AssetDetailImage from '../../molecules/AssetDetailImage/AssetDetailImage';
import Breakpoints from '../../../helpers/Breakpoints';
import IconButtons from '../../molecules/IconButtons/IconButtons';

class AssetDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: this.props.assets.find(
        assetInArray => assetInArray.id === Number(getAssetIdFromSearch(history.location.search))
      )
    };
  }

  getBacklinkText = () => {
    let text = this.props.intl.formatMessage({ id: 'asset-detail-view.link.back-to-overview-short' });

    if (window.innerWidth >= Breakpoints.breakpointL) {
      text = this.props.intl.formatMessage({ id: 'asset-detail-view.link.back-to-overview-long' });
    }

    return text;
  };

  getButtons = () => {
    let cartButtonTestId = 'addAssetToAssetCartButton';
    let cartButtonText = this.props.intl.formatMessage({ id: 'asset-tile.button.add-to-asset-cart' });
    let cartButtonClassModifier = '--add';
    let cartButtonFunction = e => {
      e.preventDefault();
      this.props.assetCartContext.onAddAssetToAssetCart(this.state.asset);
    };

    const buttonShouldDelete = this.props.assetCartContext.onHandleAssetForAssetCart(this.state.asset.id);
    if (buttonShouldDelete) {
      cartButtonTestId = 'deleteAssetFromAssetCartButton';
      cartButtonText = this.props.intl.formatMessage({ id: 'asset-tile.button.delete-from-asset-cart' });
      cartButtonClassModifier = '--remove';
      cartButtonFunction = e => {
        e.preventDefault();
        this.props.assetCartContext.onDeleteAssetFromAssetCart(this.state.asset.id);
      };
    }

    const downloadButtonFunction = e => {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = `${process.env.REACT_APP_DOWNLOAD_URL}/download?id=${this.state.asset.id}`;
    };

    return [
      {
        buttonFunction: downloadButtonFunction,
        buttonClassNameIconExtension: '--download',
        buttonText: this.props.intl.formatMessage({ id: 'asset-tile.button.download' }),
        testId: 'download-test-id'
      },
      {
        buttonFunction: cartButtonFunction,
        buttonClassNameIconExtension: cartButtonClassModifier,
        buttonText: cartButtonText,
        testId: cartButtonTestId
      }
    ];
  };

  getNavigationButtons = () => {
    if (this.props.assets.length > 1) {
      return (
        <Fragment>
          <Button
            className="asset-detail-view__asset-navigation-button
                  asset-detail-view__asset-navigation-button-prev"
            data-testid="displayPrevAssetButton"
            onClick={this.displayPrevAsset}
            title={this.props.intl.formatMessage({ id: 'asset-detail-view.link.previous' })}
          />
          <Button
            className="asset-detail-view__asset-navigation-button
            asset-detail-view__asset-navigation-button-next"
            data-testid="displayNextAssetButton"
            onClick={this.displayNextAsset}
            title={this.props.intl.formatMessage({ id: 'asset-detail-view.link.next' })}
          />
        </Fragment>
      );
    }
    return null;
  };

  displayNextAsset = () => {
    const index = this.props.assets.indexOf(this.state.asset);
    if (index >= 0 && index < this.props.assets.length - 1) {
      history.push(
        `${history.location.pathname}?${MediathekUrlSearchParams.asset}=${
          this.props.assets[index + 1].id
        }`
      );
      this.setState(() => ({ asset: this.props.assets[index + 1] }));

      if (this.props.isAssetCartPage) {
        this.props.assetCartContext.setActiveAssetInAssetCart(this.props.assets[index + 1]);
      }
      if (this.props.isCategoryPage) {
        this.props.categoryContext.setActiveAssetInCategory(this.props.assets[index + 1]);
      }
    } else if (index === this.props.assets.length - 1) {
      this.setState(() => ({ asset: this.props.assets[0] }));
      history.push(
        `${history.location.pathname}?${MediathekUrlSearchParams.asset}=${
          this.props.assets[0].id
        }`
      );
      if (this.props.isAssetCartPage) {
        this.props.assetCartContext.setActiveAssetInAssetCart(this.props.assets[0]);
      }
      if (this.props.isCategoryPage) {
        this.props.categoryContext.setActiveAssetInCategory(this.props.assets[0]);
      }
    }
  };

  displayPrevAsset = () => {
    const index = this.props.assets.indexOf(this.state.asset);
    if (index === 0) {
      history.push(
        `${history.location.pathname}?${MediathekUrlSearchParams.asset}=${
          this.props.assets[this.props.assets.length - 1].id
        }`
      );
      this.setState(() => ({
        asset: this.props.assets[this.props.assets.length - 1]
      }));

      if (this.props.isAssetCartPage) {
        this.props.assetCartContext.setActiveAssetInAssetCart(this.props.assets[this.props.assets.length - 1]);
      }
      if (this.props.isCategoryPage) {
        this.props.categoryContext.setActiveAssetInCategory(this.props.assets[this.props.assets.length - 1]);
      }
    } else if (index > 0 && index <= this.props.assets.length - 1) {
      history.push(
        `${history.location.pathname}?${MediathekUrlSearchParams.asset}=${
          this.props.assets[index - 1].id
        }`
      );
      this.setState(() => ({ asset: this.props.assets[index - 1] }));
      if (this.props.isAssetCartPage) {
        this.props.assetCartContext.setActiveAssetInAssetCart(this.props.assets[index - 1]);
      }
      if (this.props.isCategoryPage) {
        this.props.categoryContext.setActiveAssetInCategory(this.props.assets[index - 1]);
      }
    }
  };

  render() {
    const buttons = this.getButtons();

    const backlinkText = this.getBacklinkText();

    const navigationButtons = this.getNavigationButtons();

    return (
      <div className="asset-detail-view">
        {this.state.asset ? (
          <Fragment>
            <div className="asset-detail-view__asset-navigation">
              <Link className="asset-detail-view__asset-navigation-link" to={this.props.backToViewLink}>
                {backlinkText}
              </Link>
              <div className="asset-detail-view__asset-navigation-button-container">
                {navigationButtons}
                <Link
                  className="asset-detail-view__asset-navigation-button
              asset-detail-view__asset-navigation-button-close"
                  to={this.props.backToViewLink}
                  title={backlinkText}
                />
              </div>
            </div>
            <h1 className="asset-detail-view__title">{`${this.state.asset.name}`}</h1>
            <Link
              className="asset-detail-view__category"
              to={this.state.asset.categoryPath}
            >
              <span className="asset-detail-view__category-text">
                {this.state.asset.categoryName}
              </span>
            </Link>
            <div className="asset-detail-view__wrapper">
              <AssetDetailImage
                path={this.state.asset.thumbnailFull}
                name={this.state.asset.name}
              />
              <div className="asset-detail-view__data">
                <AssetDetailProperties
                  extension={this.state.asset.metadataAttributeList.extension}
                  size={this.state.asset.metadataAttributeList.size}
                  dimension={this.state.asset.metadataAttributeList.dimension}
                  published={this.state.asset.metadataAttributeList.published}
                  colorType={this.state.asset.metadataAttributeList.colorType}
                  resolution={this.state.asset.metadataAttributeList.resolution}
                />

                <IconButtons
                  containerClassName="asset-detail-view__buttons"
                  buttons={buttons}
                />

                <AssetDetailInformation
                  description={this.state.asset.metadataFormattedText.description}
                  copyright={this.state.asset.metadataFormattedText.copyright}
                  usageRights={this.state.asset.metadataFormattedText.usageRights}
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <span className="asset-detail-view__error-message">
            {`Error: asset with id: ${this.props.activeAssetId} is not found`}
          </span>
        )}
      </div>
    );
  }
}

export default injectIntl(withCategory(withAssetCart(AssetDetailView)));
