import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import history from '../../../routers/history';
import PageTemplate from '../../templates/PageTemplate/PageTemplate';
import { withHomeContent } from '../../../store/HomeContentContext';
import AssetDetailView from '../../organisms/AssetDetailView/AssetDetailView';
import HomeContentView from '../../organisms/HomeContentView/HomeContentView';
import NavigationLinks from '../../../helpers/NavigationLinks';
import MediathekUrlSearchParams from '../../../helpers/MediathekUrlSearchParams';
import prepareStateValuesForAssetDetailView from '../../../helpers/functions/prepareStateValuesForAssetDetailView';

class HomePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAssetDetailView: false,
    };

    // load the home content data only when the page is requested
    this.props.homeContentContext.getHomeContent();

    // redirect to home page if the user is not trying to navigate to the latests details
    if (!history.location.pathname.includes(NavigationLinks.latestPageLink)
      || !history.location.search.includes(`${MediathekUrlSearchParams.asset}=`)) {
      history.push(NavigationLinks.homePageLink);
    }
  }

  componentDidUpdate(_, prevState) {
    this.updateStateForAssetDetailView(prevState);
  }

  updateStateForAssetDetailView = prevState => {
    if (
      !this.props.homeContentContext.error
      && this.props.homeContentContext.latestAssets.length > 0
    ) {
      const stateValues = prepareStateValuesForAssetDetailView(
        prevState,
        history.location.search
      );
      if (stateValues) {
        const { isAssetDetailView } = stateValues;
        this.setState(() => ({ isAssetDetailView }));
      }
    }
  };

  renderView = () => (this.state.isAssetDetailView ? (
    <AssetDetailView
      assets={this.props.homeContentContext.latestAssets}
      backToViewLink={NavigationLinks.homePageLink}
      folderName="Latest"
    />
  ) : (
    <HomeContentView />
  ));

  render() {
    const {
      homeContentContext: { error, isLoading }
    } = this.props;

    return (
      <PageTemplate>
        {isLoading && <FormattedMessage id="loading.text" />}
        {!error ? this.renderView() : <div>{error}</div>}
      </PageTemplate>
    );
  }
}

export default withHomeContent(HomePage);
