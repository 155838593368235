import MediathekUrlSearchParams from '../MediathekUrlSearchParams';

export default search => {
  let assetId = null;

  if (search) {
    assetId = new URLSearchParams(search).get(MediathekUrlSearchParams.asset);
  }

  return assetId;
};
