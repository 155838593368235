import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HomeIcon from '../HomeIcon/HomeIcon';

const HomeNavLink = props => (
  <NavLink className="home-nav-link" {...props}>
    <HomeIcon />
    <div className="home-nav-link__text">
      <FormattedMessage id="home-nav-link.text" />
    </div>
  </NavLink>
);

export default HomeNavLink;
