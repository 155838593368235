import React, { Fragment } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Tracking from '../../../helpers/tracking/tracking';


function shouldShowTrackingNotice() {
  return Tracking.getStatus() === Tracking.NOTDEFINED;
}

function displayOrRemove() {
  const trackingNoticeElement = document.getElementById('js-tracking-notice');
  if (trackingNoticeElement === null) {
    return;
  }

  if (shouldShowTrackingNotice()) {
    trackingNoticeElement.style.display = 'block';
  } else {
    // trackingNoticeElement.parentNode.removeChild(trackingNoticeElement);
    trackingNoticeElement.style.display = 'none';
  }
}
let isOptOutNotice = false;

function acceptCookies() {
  Tracking.setStatus(Tracking.ACCEPTED);
  window.location.reload();
}

function ignoreCookies() {
  Tracking.setStatus(Tracking.IGNORED);
  window.location.reload();
}

function optOut() {
  if (Tracking.getStatus() === Tracking.IGNORED) {
    acceptCookies();
  } else if (Tracking.getStatus() === Tracking.ACCEPTED) {
    ignoreCookies();
  }
  isOptOutNotice = false;
}
function closeBox() {
  isOptOutNotice = false;
  const optOutBanner = document.getElementById('js-tracking-notice');
  optOutBanner.style.display = 'none';
}


let trackingNoticeText = 'tracking-notice.description-about.text';
let primaryButtonFunction = acceptCookies;
let primaryButtonText = 'tracking-notice.button.accept.label';
let secondaryButtonFunction = ignoreCookies;
let secondaryButtonText = 'tracking-notice.button.ignore.label';

if (Tracking.getStatus() !== Tracking.NOTDEFINED) {
  isOptOutNotice = true;
}


if (isOptOutNotice) {
  trackingNoticeText = 'tracking-notice.description-opt-out.link.text';
  primaryButtonFunction = optOut;

  if (Tracking.getStatus() === Tracking.ACCEPTED) {
    primaryButtonText = 'tracking-notice.button.opt-out.label';
  } else if (Tracking.getStatus() === Tracking.IGNORED) {
    primaryButtonText = 'tracking-notice.button.opt-in.label';
  }

  secondaryButtonFunction = closeBox;
  secondaryButtonText = 'tracking-notice.button.close.label';
}

const TrackingNotice = () => (
  <Fragment>
    { displayOrRemove() }

    <div className="tracking-notice" id="js-tracking-notice">
      <div className="tracking-notice__inner-wrapper">
        <div className="tracking-notice__text">
          <FormattedHTMLMessage id={trackingNoticeText} />
          {!isOptOutNotice ? (
            <div className="tracking-notice__link-container">
              <a
                className="tracking-notice__link"
                href="https://www.krombacher.de/mediathek/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >

                <FormattedHTMLMessage id="tracking-notice.description.link.text" />
              </a>
            </div>
          ) : ('')
          }

        </div>
        <div className="tracking-notice__buttons">
          <div
            role="button"
            tabIndex="0"
            className="tracking-notice__button tracking-notice__button--secondary"
            onClick={secondaryButtonFunction}
            onKeyDown={secondaryButtonFunction}
          >
            <FormattedMessage id={secondaryButtonText} />
          </div>

          <div
            role="button"
            tabIndex="0"
            className="tracking-notice__button tracking-notice__button--primary"
            onClick={primaryButtonFunction}
            onKeyDown={primaryButtonFunction}
          >
            <FormattedMessage id={primaryButtonText} />
          </div>
        </div>
      </div>
    </div>
  </Fragment>

);

export default TrackingNotice;
