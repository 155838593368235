import React from 'react';
import { FormattedMessage } from 'react-intl';

const AssetDetailInformation = props => {
  let description = '';

  if (props.description && props.description.trim() !== '') {
    description = (
      <div className="asset-detail-information__description">
        {props.description}
      </div>
    );
  }

  const noData = (<FormattedMessage id="asset-detail-properties.no-data" />);

  return (
    <div className="asset-detail-information">
      <div className="asset-detail-information__tab-header">
        <FormattedMessage
          id="asset-detail-information.description"
        />
      </div>
      <div className="asset-detail-information__tab-data">
        { description }
        <div className="asset-detail-information__additional-properties">
          <span className="asset-detail-information__additional-properties-name">
            <FormattedMessage
              id="asset-detail-information.source"
            />
          </span>
          <span className="asset-detail-information__additional-properties-value">
            {props.copyright ? props.copyright : noData}
          </span>
        </div>
        <div className="asset-detail-information__additional-properties">
          <span className="asset-detail-information__additional-properties-name">
            <FormattedMessage
              id="asset-detail-information.licence"
            />
          </span>
          <span className="asset-detail-information__additional-properties-value">
            {props.usageRights ? props.usageRights : noData}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailInformation;
