import React, { Component } from 'react';
import { getCategories } from './api';

const CategoriesContext = React.createContext();
const { Provider, Consumer } = CategoriesContext;

class CategoriesProvider extends Component {
  /* eslint-disable react/no-unused-state, no-param-reassign */
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoriesMap: null,
      activeCategory: null,
      error: null,
      isLoading: false,
      onCategory: this.handleActiveCategory,
      updateCategoriesVisibility: this.updateCategoriesVisibility,
    };
  }

  async componentDidMount() {
    this.setState(() => ({ error: null, isLoading: true }));

    const categories = await getCategories();
    const categoriesMap = new Map();
    this.setParent(categories.payload, null, categoriesMap);

    if (!categories.error) {
      this.setState(() => ({
        isLoading: false,
        categories: categories.payload,
        categoriesMap
      }));
    } else {
      this.setState(() => ({ isLoading: false, error: categories.error }));
    }
  }

  setParent(categories, parent, map) {
    categories.forEach(category => {
      // set new parent property to the category object
      category.parent = parent;
      category.isVisible = !parent;
      category.isFirstLevel = !parent;
      map.set(category.id, category);
      if (category.children && category.children.length > 0) {
        this.setParent(category.children, category, map);
      }
    });
  }

  handleActiveCategory = activeCategory => {
    this.setState(() => ({ activeCategory }));
  };
  /* eslint-enable react/no-unused-state, no-param-reassign */

  updateCategoriesVisibility = categoryId => {
    const copyMap = new Map();

    this.state.categoriesMap.forEach((value, key) => {
      const newValue = value;
      // reset all visibilities
      newValue.isVisible = !value.parent; // visible is true, if parent is null
      newValue.isFirstLevel = !value.parent;
      copyMap.set(key, newValue);
    });

    if (categoryId && copyMap.get(categoryId)) {
      // get active category
      const activeCategory = copyMap.get(categoryId);
      // set it's visibility to true
      activeCategory.isVisible = true;

      this.setChildrensVisibility(activeCategory.children, true);

      this.setParentsVisibility(activeCategory, true);

      this.setState(prevState => ({
        categoriesMap: copyMap,
        categories: [...prevState.categories]
      }));
    }
  };

  setChildrensVisibility = (children, isVisible) => {
    if (children && children.length > 0) {
      children.forEach(category => {
        category.isVisible = isVisible; // eslint-disable-line
      });
    }
  };

  setParentsVisibility = (category, isVisible) => {
    if (category.parent) {
      category.parent.isVisible = isVisible; // eslint-disable-line
      this.setParentsVisibility(category.parent, isVisible);
      this.setChildrensVisibility(category.parent.children, true);
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

function withCategories(WrappedComponent) {
  return function WithCategories(props) {
    return (
      <Consumer>
        {categoriesContext => {
          if (typeof categoriesContext === 'undefined') {
            throw Error('withCategories requires an CategoriesProvider');
          }
          return (
            <WrappedComponent
              {...props}
              categoriesContext={categoriesContext}
            />
          );
        }}
      </Consumer>
    );
  };
}

export { CategoriesProvider, CategoriesContext, withCategories };
